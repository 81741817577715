import React, { Fragment, useEffect, useRef, useState } from "react";
import Image from "react-bootstrap/Image";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import { getApi } from "../../services";
import axios from "axios";
import { PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { Resetdata, UpdatePaymentData } from "../../redux/User/actions";
import { setThemeColor } from "../../redux/theme/action/themeActions";
import { themeReducer,ApiState } from "../../redux";
import { UpdateApiData,AResetData } from '../../redux/Api/actions';
import Cookies from "js-cookie";
type DocDetails = {
  [key: string]: any;
};
type Doctor ={
  [key: string]: any;
}
export const Book: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const[Patient_Alert,setAlert] = useState()
  const shouldLog = useRef(true);
  const getapi = getApi();
  const [details, setDetails] = useState<DocDetails>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [reasondata, setReasonData] = useState<any>([]);
  const location = useLocation();
  useEffect(()=>{
    if (shouldLog.current) {
      shouldLog.current = false;
    dispatch(Resetdata())
    dispatch(AResetData())
    }
  },[])
  
  useEffect(() =>{
return () =>{
 
}
  },[])

  useEffect(()=>{
    getData2()   
  },[location])


let locationid2 = location.search.split('?')
const [location_id2,setlocationid]=useState(locationid2[1]? atob(locationid2[1]):'')




useEffect(()=>{
  
  dispatch(UpdateApiData('login_from',location.pathname.split('/')));

  let ll = location_id2.split('_')
if (ll[0] === 'S25880ANA' ){
dispatch(setThemeColor('pink'));
}
 else{
  dispatch(setThemeColor('blue'));
 }
},[location_id2])
 
const apistate = useSelector<ApiState>((state) => state.ApiReducer)
// const storeDoctorData = (doctor: Doctor) => {
//   console.log('doctor details',doctor) 
//   const jsonString = JSON.stringify(doctor);
//  // Cookies.set("selecteddate", doctor.APTDate)
//   Cookies.set("selecteddate", doctor.ADATE) 
// // Set the expiration date to one day from the current date
// let expirationDate = new Date();
// expirationDate.setDate(expirationDate.getDate() + 1);


//   // Set the cookie with the JSON string
//   document.cookie = `DoctorDetails=${encodeURIComponent(jsonString)}; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/`;
// };

const storeDoctorData = (doctor: Doctor) => {
  // Create a new object without the unwanted fields
  const { REMARKS,NAMETYPE,AHPRA,UNIQUENAME,VCF,PROFILE_PHOTO, BILLING_DETAILS, PROFILE_NOTE, ...filteredDoctor } = doctor;
  
  // Convert the filtered doctor object to JSON string
  const jsonString = JSON.stringify(filteredDoctor);
  
  // Set the expiration date to one day from the current date
  let expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);
  
  // Set the cookie with the JSON string
  document.cookie = `DoctorDetails=${encodeURIComponent(jsonString)}; expires=0; path=/`;
};


  const getData = () => {
    
    setLoading(true);
    const urlSearchString = window.location.search;
    const UrlParamData = urlSearchString.slice(1);
    try {
      const decodedData = atob(UrlParamData);
      // Do something with the decoded string
      const UrlParamDataArray = decodedData.split("_");
      const location_id = UrlParamDataArray[0];
      const Userid = UrlParamDataArray[1];
      dispatch(UpdatePaymentData("location_id",location_id));

      let data = {
        location_id: location_id,
        doctorlist_order:Cookies.get('Doctors_ListingOrder') ? Cookies.get('Doctors_ListingOrder') : "1", 
      };

      axios
      .get(
        'https://coddgpphp.coddapps.com.au/config/onlineapt/API.php?GET_TYPE=book&PARAM='+UrlParamData
      )
      .then((response) => {
        
      }).catch((err) => {
       

      });


      getapi
      .post("doctorprofileview/", data)
      .then((res) => {
        setLoading(false);
        
        const doctorList = res.data.data;
        for (const item of doctorList) {
          if (item.LOCATIONID == location_id && item.USERID == Userid) {
   
            storeDoctorData(item)
         
          
            Cookies.set("userid", item.USERID)
            dispatch(UpdateApiData("location_id", location_id));
            dispatch(UpdateApiData("selecteddate", item.ADATE));
            dispatch(UpdateApiData("APTTime", item.ATIME));
            Cookies.set("selecteddate", item.ADATE)
            dispatch(UpdateApiData("userid",item.USERID));
            dispatch(UpdateApiData("APTDate", item.ADATE));
            getReasonData();
     
             if(searchRedux.Patient_Alert === 1){
        
              navigate("/profile/");
             }
             else{
              navigate("/profile");
             }
       
          }
          else{
          
            setLoading(false);
          }
        }

       
          redirecting_page()
         

      })
      .catch((err) => {
     
        setLoading(false);
      });  
    } catch (error) {
      console.error("Error decoding Base64 string:", error);
      setLoading(false);
    }

   
   
   


  };





  const [listorder,setlistorder]= useState('')
  const [serverdown,setserverdown]= useState('')
  const getData2 = () => {
    
    setLoading(true)
    const urlSearchString = window.location.search;
    const UrlParamData = urlSearchString.slice(1);
    const decodedData = atob(UrlParamData);
    // Do something with the decoded string
    const UrlParamDataArray = decodedData.split("_");
    const location_id = UrlParamDataArray[0];

    let data = {
      location_id: location_id,
    };

    

    getapi
      .post("practicedetails/", data)
      .then((res) => {
        setLoading(false)
      
        setserverdown(res.data.data[0].Is_ServerDown_Msg)
        setDetails(res.data.data)
        setAlert(res.data.data[0].Patient_Alert)
      dispatch(UpdatePaymentData("Is_ServerDown_Msg", res.data.data[0].Is_ServerDown_Msg));
        // dispatch(UpdatePaymentData("BTN_1_URL", res.data.data[0].BTN_1_URL));
        // dispatch(UpdatePaymentData("BTN_1", res.data.data[0].BTN_1));
        dispatch(
          UpdatePaymentData("Location_Name", res.data.data[0].Location_Name)
        );
        Cookies.set('Address',res.data.data[0].Address1)
        Cookies.set('Location_Name', res.data.data[0].Location_Name)
        // Cookies.set('BTN_1_URL', res.data.data[0].BTN_1_URL)
        // Cookies.set('BTN_1', res.data.data[0].BTN_1)
        Cookies.set('Doctors_ListingOrder', res.data.data[0].Doctors_ListingOrder)

        dispatch(
          UpdateApiData("Doctors_ListingOrder", res.data.data[0].Doctors_ListingOrder)
        );
        setlistorder(res.data.data[0].Doctors_ListingOrder)
      
        if(res.data.data[0].Is_ServerDown_Msg){
       
          navigate('/serverdown')
        }
        else {

          getData()
              }

      })
      .catch((err) => {
     
        setLoading(false);
 
 });
  };






  const getReasonData = () => {
    const urlSearchString = window.location.search;
    const UrlParamData = urlSearchString.slice(1);
    const decodedData = atob(UrlParamData);
    const UrlParamDataArray = decodedData.split("_");
    const location_id = UrlParamDataArray[0];
    const Userid = UrlParamDataArray[1];
    
  
    let data = {
      location_id: location_id,
      user_id:Userid
    };

   
    getapi
      .post("aptreasonbyuser/", data)
      .then((res) => {
      
        setReasonData(res.data.data);
  
        // var jsonString = JSON.stringify(res.data.data);
        // document.cookie = 'aptreasonbyuser=' + encodeURIComponent(jsonString);
      })
      .catch((err) => {
       
      });
  };

//   useEffect(() => {
//     const clearCookiesOnTabClose = () => {
//         // Clear the cookies here
//         Cookies.remove("selecteddate");
//         document.cookie = "DoctorDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
//     };
 
//     window.addEventListener('beforeunload', clearCookiesOnTabClose);

//     return () => {
//         window.removeEventListener('beforeunload', clearCookiesOnTabClose);
//     };
// }, [location]); 

// useEffect(() => {
//   const clearCookiesOnTabClose = () => {
//       // Clear the cookies here
//       Cookies.remove("selecteddate");
//       document.cookie = "DoctorDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
//   };

//   window.addEventListener('beforeunload', clearCookiesOnTabClose);

//   return () => {
//       window.removeEventListener('beforeunload', clearCookiesOnTabClose);
//   };
// }, []); 

useEffect(() => {
  const clearCookiesOnTabClose = () => {
    document.cookie = "APTReason=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // Clear the cookies here
      Cookies.remove("selecteddate");
      Cookies.remove('Location_Name')
      // Cookies.remove('BTN_1_URL')
      // Cookies.remove('BTN_1')
      Cookies.remove('Doctors_ListingOrder') 
      Cookies.remove('selectedSlotCount')
      Cookies.remove('userid')

      document.cookie = "DoctorDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    
      
       dispatch(Resetdata())
       dispatch(AResetData())
     
  };

  window.addEventListener('beforeunload', clearCookiesOnTabClose);
  window.addEventListener('unload', clearCookiesOnTabClose);

  return () => {
      window.removeEventListener('beforeunload', clearCookiesOnTabClose);
      window.removeEventListener('unload', clearCookiesOnTabClose);
  };
}, [location]); 

useEffect(() => {
const clearCookiesOnTabClose = () => {
  // Clear the cookies here
  document.cookie = "APTReason=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  Cookies.remove("selecteddate");
  Cookies.remove('Location_Name')
  // Cookies.remove('BTN_1_URL')
  // Cookies.remove('BTN_1')
  Cookies.remove('Doctors_ListingOrder') 
  Cookies.remove('selectedSlotCount')
  Cookies.remove('userid')

  document.cookie = "DoctorDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  
   dispatch(Resetdata())
   dispatch(AResetData())
 
}; 

window.addEventListener('beforeunload', clearCookiesOnTabClose);
window.addEventListener('unload', clearCookiesOnTabClose);

return () => {
  window.removeEventListener('beforeunload', clearCookiesOnTabClose);
  window.removeEventListener('unload', clearCookiesOnTabClose);
};
}, []); 



  const redirecting_page = () => {

    
    if (searchRedux.Patient_Alert === 1) {
     
        navigate('/profile/');
    
    } else {
      navigate('/profile');
    }
  }


  return (
    <div className="box-design p-10">
      {loading ? (
        <div className="d-flex align-items-center loader justify-content-center">
          <CircularProgress />
        </div>
      ) : (
     
          <Fragment>
            <div className="d-flex align-items-center loader justify-content-center">

              <Image
                rounded
                width={216}
                height={194}
                className="d-inline-flex align-left username object-fit-cover align-middle"
                src={
                  require("../../assets/images/Datanotfound.png")
                }             

              />

            </div>
            <Typography className="d-flex mt-5 align-items-center loader justify-content-center">No data found</Typography>
          </Fragment>
      )}
    </div>
  );
};
